var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-column" },
    [
      _c("waveform-view-toolbar", {
        ref: "waveformViewToolbar",
        attrs: {
          "shift-key-pressed": _vm.$data.$_shiftKeyPressed,
          "is-single-audio-segment-selected":
            _vm.$_isSingleAudioSegmentSelected,
          "is-any-audio-segment-selected": _vm.$_isAnyAudioSegmentSelected,
          "is-audio-segment-label-copied": _vm.$_isAudioSegmentLabelCopied,
          "is-auto-follow-loop-definition-enabled":
            _vm.$data.$_isAutoFollowLoopDefinitionEnabled,
          "is-undo-disabled": _vm.isUndoDisabled,
          "is-redo-disabled": _vm.isRedoDisabled,
        },
        on: {
          "copy-audio-segment-label": _vm.$_copyAudioSegmentLabel,
          "paste-audio-segment-label": _vm.$_pasteAudioSegmentLabel,
          "enable-auto-follow-loop-definition":
            _vm.$_enableAutoFollowLoopDefinition,
          "disable-auto-follow-loop-definition":
            _vm.$_disableAutoFollowLoopDefinition,
        },
        model: {
          value: _vm.$data.$_controlMode,
          callback: function ($$v) {
            _vm.$set(_vm.$data, "$_controlMode", $$v)
          },
          expression: "$data.$_controlMode",
        },
      }),
      _c("waveform-canvas-container", {
        ref: "waveformCanvasContainer",
        attrs: {
          id: "waveform-canvas-container",
          "label-sequence": _vm.labelSequence,
          "selected-audio-segment-idcs": _vm.selectedAudioSegmentIdcs,
          "audio-buffer": _vm.audioBuffer,
          "waveform-digest": _vm.waveformDigest,
          "canvas-sample-offset-min": _vm.$_sampleOffsetMin,
          "canvas-sample-offset-max": _vm.$_sampleOffsetMax,
          "play-time-sec": _vm.playTimeSec,
          "control-mode": _vm.$data.$_controlMode,
          "shift-key-pressed": _vm.$data.$_shiftKeyPressed,
        },
        model: {
          value: _vm.$_audioSegmentSequence,
          callback: function ($$v) {
            _vm.$_audioSegmentSequence = $$v
          },
          expression: "$_audioSegmentSequence",
        },
      }),
      _c("selected-audio-segment-editor-pane", {
        ref: "selectedAudioSegmentEditorPane",
        attrs: {
          id: "selected-waveform-view",
          "label-sequence": _vm.labelSequence,
          "selected-audio-segment-idcs": _vm.selectedAudioSegmentIdcs,
          "time-unit": _vm.timeUnit,
          "waveform-digest": _vm.waveformDigest,
          "is-auto-follow-loop-definition-enabled":
            _vm.$data.$_isAutoFollowLoopDefinitionEnabled,
          "control-mode": _vm.$data.$_controlMode,
          "shift-key-pressed": _vm.$data.$_shiftKeyPressed,
        },
        on: { "update-label-sequence": _vm.$_onUpdateLabelSequence },
        model: {
          value: _vm.$_audioSegmentSequence,
          callback: function ($$v) {
            _vm.$_audioSegmentSequence = $$v
          },
          expression: "$_audioSegmentSequence",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }