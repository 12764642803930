var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "d-flex flex-column" },
    [
      _vm.$_isAnyAudioSegmentSelected
        ? _c(
            "v-row",
            { staticClass: "fill-height" },
            [
              _c(
                "v-col",
                { staticClass: "d-flex flex-column", attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "caption" }, [
                    _vm._v(" Begin "),
                    _c("span", { staticClass: "time-unit" }, [
                      _vm._v(_vm._s(_vm.$_timeUnitSuffix)),
                    ]),
                  ]),
                  _c("editable-text", {
                    attrs: {
                      readonly: _vm.$_isSelectedAudioSegmentBeginReadonly,
                      validator: _vm.$_beginValidator,
                    },
                    model: {
                      value: _vm.$_selectedAudioSegmentBegin,
                      callback: function ($$v) {
                        _vm.$_selectedAudioSegmentBegin = $$v
                      },
                      expression: "$_selectedAudioSegmentBegin",
                    },
                  }),
                  _vm.$_isSingleSegmentSelected
                    ? _c("scoped-waveform-canvas-container", {
                        attrs: {
                          begin: "",
                          "waveform-digest": _vm.waveformDigest,
                          "audio-segment-idx": _vm.$_singlySelectedSegmentIdx,
                          "num-context-samples":
                            _vm.$data.$_numScopedWaveformContextSamples,
                          readonly: _vm.$_isSelectedAudioSegmentBeginReadonly,
                          "control-mode": _vm.controlMode,
                          "shift-key-pressed": _vm.shiftKeyPressed,
                          "sample-offset": _vm.$data.$_beginSampleOffset,
                        },
                        on: {
                          "register-component-instance":
                            _vm.$_registerBeginWaveformCanvasContainerInstance,
                          "unregister-component-instance":
                            _vm.$_unregisterBeginWaveformCanvasContainerInstance,
                          update: _vm.$_onUpdate,
                          "update-num-context-samples":
                            _vm.$_onUpdateNumContextSamples,
                          offset: _vm.$_onOffsetBegin,
                        },
                        model: {
                          value: _vm.$_audioSegmentSequence,
                          callback: function ($$v) {
                            _vm.$_audioSegmentSequence = $$v
                          },
                          expression: "$_audioSegmentSequence",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex flex-column", attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "caption" }, [_vm._v("Label")]),
                  _c("editable-text", {
                    staticClass: "text-field",
                    model: {
                      value: _vm.$_selectedLabel,
                      callback: function ($$v) {
                        _vm.$_selectedLabel = $$v
                      },
                      expression: "$_selectedLabel",
                    },
                  }),
                  _vm.$_isSingleSegmentSelected
                    ? _c("audio-segment-waveform-canvas-container", {
                        attrs: {
                          "waveform-digest": _vm.waveformDigest,
                          "audio-segment-sequence": _vm.audioSegmentSequence,
                          "audio-segment-idx": _vm.$_singlySelectedSegmentIdx,
                          "begin-sample-offset": _vm.$data.$_beginSampleOffset,
                          "end-sample-offset": _vm.$data.$_endSampleOffset,
                        },
                        on: {
                          "register-component-instance":
                            _vm.$_registerAudioSegmentWaveformCanvasContainerInstance,
                          "unregister-component-instance":
                            _vm.$_unregisterAudioSegmentWaveformCanvasContainerInstance,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "d-flex flex-column", attrs: { cols: "4" } },
                [
                  _c("label", { staticClass: "caption" }, [
                    _vm._v(" End "),
                    _c("span", { staticClass: "time-unit" }, [
                      _vm._v(_vm._s(_vm.$_timeUnitSuffix)),
                    ]),
                  ]),
                  _c("editable-text", {
                    attrs: {
                      readonly: _vm.$_isSelectedAudioSegmentEndReadonly,
                      validator: _vm.$_endValidator,
                    },
                    model: {
                      value: _vm.$_selectedAudioSegmentEnd,
                      callback: function ($$v) {
                        _vm.$_selectedAudioSegmentEnd = $$v
                      },
                      expression: "$_selectedAudioSegmentEnd",
                    },
                  }),
                  _vm.$_isSingleSegmentSelected
                    ? _c("scoped-waveform-canvas-container", {
                        attrs: {
                          end: "",
                          "waveform-digest": _vm.waveformDigest,
                          "audio-segment-idx": _vm.$_singlySelectedSegmentIdx,
                          "num-context-samples":
                            _vm.$data.$_numScopedWaveformContextSamples,
                          readonly: _vm.$_isSelectedAudioSegmentEndReadonly,
                          "control-mode": _vm.controlMode,
                          "shift-key-pressed": _vm.shiftKeyPressed,
                          "sample-offset": _vm.$data.$_endSampleOffset,
                        },
                        on: {
                          "register-component-instance":
                            _vm.$_registerEndWaveformCanvasContainerInstance,
                          "unregister-component-instance":
                            _vm.$_unregisterEndWaveformCanvasContainerInstance,
                          update: _vm.$_onUpdate,
                          "update-num-context-samples":
                            _vm.$_onUpdateNumContextSamples,
                          offset: _vm.$_onOffsetEnd,
                        },
                        model: {
                          value: _vm.$_audioSegmentSequence,
                          callback: function ($$v) {
                            _vm.$_audioSegmentSequence = $$v
                          },
                          expression: "$_audioSegmentSequence",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "v-row",
            { attrs: { align: "center" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "12", align: "center", justify: "center" } },
                [_vm._v(" No audio segment is selected. ")]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }