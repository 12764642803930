var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-data-table", {
    attrs: {
      dense: "",
      "hide-default-footer": "",
      headers: _vm.$_headers,
      items: _vm.$_items,
      "items-per-page": -1,
    },
    scopedSlots: _vm._u([
      {
        key: "item",
        fn: function ({ index, item }) {
          return [
            _c(
              "tr",
              {
                staticClass: "audio-segment-list-row",
                style: _vm.$_rowStyles[index],
                on: {
                  click: function ($event) {
                    return _vm.$_onClickRow($event, index)
                  },
                },
              },
              [
                _c(
                  "td",
                  { staticClass: "px-1" },
                  [
                    _c("editable-text", {
                      attrs: {
                        simple: "",
                        value: item.beginString,
                        readonly: _vm.$_isBeginReadonly[index],
                        validator: _vm.$_generateBeginValidator(index),
                      },
                      on: {
                        update: function ($event) {
                          return _vm.$_updateBegin(index, $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "px-1" },
                  [
                    _c("editable-text", {
                      attrs: {
                        simple: "",
                        value: item.endString,
                        readonly: _vm.$_isEndReadonly[index],
                        validator: _vm.$_generateEndValidator(index),
                      },
                      on: {
                        update: function ($event) {
                          return _vm.$_updateEnd(index, $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "td",
                  { staticClass: "px-1" },
                  [
                    _c("editable-text", {
                      attrs: { simple: "", value: item.label },
                      on: {
                        update: function ($event) {
                          return _vm.$_updateLabel(index, $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }