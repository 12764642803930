var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-navigation-drawer",
    {
      ref: "drawer",
      attrs: {
        color: "grey lighten-5",
        app: "",
        right: "",
        clipped: "",
        "hide-overlay": "",
        width: _vm.navigation.width,
      },
      model: {
        value: _vm.navigation.shown,
        callback: function ($$v) {
          _vm.$set(_vm.navigation, "shown", $$v)
        },
        expression: "navigation.shown",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "pa-4",
          staticStyle: {
            height: "50%",
            "box-sizing": "border-box",
            display: "flex",
            "flex-direction": "column",
            "user-select": "none",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pt-2 d-flex" },
            [
              _c("h3", { staticClass: "mr-2" }, [_vm._v("Nano Props (Input)")]),
              _c("copy-to-clipboard-btn", {
                attrs: { text: JSON.stringify(_vm.nanoProps, null, 4) },
              }),
            ],
            1
          ),
          _c("vue-json-pretty", {
            staticClass: "nano-object",
            attrs: { data: _vm.nanoProps },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "pa-4",
          staticStyle: {
            height: "50%",
            "box-sizing": "border-box",
            display: "flex",
            "flex-direction": "column",
            "user-select": "none",
          },
        },
        [
          _c(
            "div",
            { staticClass: "pt-2 d-flex" },
            [
              _c("h3", { staticClass: "mr-2" }, [
                _vm._v("Nano Answers (Output)"),
              ]),
              _c("copy-to-clipboard-btn", {
                attrs: { text: JSON.stringify(_vm.nanoAnswers, null, 4) },
              }),
            ],
            1
          ),
          _c("vue-json-pretty", {
            staticClass: "nano-object",
            attrs: { data: _vm.nanoAnswers },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }