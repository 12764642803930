var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pa-6" },
    [
      _c("t-audio-segmentation", {
        attrs: {
          "label-sequence": _vm.nano.ans.labelSequence,
          "audio-url": _vm.nano.props.audioUrl,
          "label-options": _vm.labelOptions,
        },
        on: {
          "update:labelSequence": function ($event) {
            return _vm.$set(_vm.nano.ans, "labelSequence", $event)
          },
          "update:label-sequence": function ($event) {
            return _vm.$set(_vm.nano.ans, "labelSequence", $event)
          },
        },
        model: {
          value: _vm.audioSegmentSequence,
          callback: function ($$v) {
            _vm.audioSegmentSequence = $$v
          },
          expression: "audioSegmentSequence",
        },
      }),
      _c(
        "div",
        { staticClass: "text-end" },
        [
          _c(
            "v-btn",
            {
              attrs: { color: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [_vm._v("Submit")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }