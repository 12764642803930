var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: { id: "editable-text" },
      on: { focusin: _vm.$_onFocusin, focusout: _vm.$_onFocusout },
    },
    [
      _c("input", {
        ref: "inputElement",
        staticClass: "flex-grow-1",
        attrs: {
          id: "text-input",
          autocomplete: "off",
          disabled: _vm.readonly,
          readonly: _vm.$_apparentlyReadonly,
        },
        on: {
          compositionstart: _vm.$_onCompositionstart,
          compositionend: _vm.$_onCompositionend,
          keydown: function ($event) {
            $event.stopPropagation()
            return _vm.$_onKeydown.apply(null, arguments)
          },
          dblclick: _vm.$_onDblclick,
          input: _vm.$_validate,
        },
      }),
      !_vm.simple
        ? [
            _vm.$data.$_isEditing
              ? [
                  _c(
                    "v-btn",
                    {
                      staticClass: "flex-grow-0",
                      attrs: { icon: "", "x-small": "" },
                      on: { mousedown: _vm.$_finishEditing },
                    },
                    [_c("v-icon", [_vm._v("mdi-check")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "flex-grow-0",
                      attrs: { icon: "", "x-small": "" },
                      on: { mousedown: _vm.$_cancelEditing },
                    },
                    [_c("v-icon", [_vm._v("mdi-cancel")])],
                    1
                  ),
                ]
              : [
                  !_vm.readonly
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", "x-small": "" },
                          on: { mousedown: _vm.$_startEditing },
                        },
                        [_c("v-icon", [_vm._v("mdi-pencil")])],
                        1
                      )
                    : _vm._e(),
                ],
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }