var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    { attrs: { id: "waveform-view-toolbar" } },
    [
      _c(
        "v-btn",
        {
          staticClass: "my-1 mr-2 py-1",
          attrs: {
            outlined: "",
            small: "",
            depressed: "",
            disabled: _vm.isUndoDisabled,
          },
          on: { click: _vm.undo },
        },
        [_c("v-icon", [_vm._v("mdi-undo")])],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "my-1 mr-2 py-1",
          attrs: {
            outlined: "",
            small: "",
            depressed: "",
            disabled: _vm.isRedoDisabled,
          },
          on: { click: _vm.redo },
        },
        [_c("v-icon", [_vm._v("mdi-redo")])],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "my-1 mr-2 py-1",
          attrs: {
            outlined: "",
            small: "",
            depressed: "",
            "input-value": _vm.isAutoFollowLoopDefinitionEnabled,
          },
          on: { click: _vm.$_toggleIsAutoFollowLoopDefinitionEnabled },
        },
        [_c("v-icon", [_vm._v("mdi-restart")])],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "my-1 mr-2 py-1",
          attrs: {
            outlined: "",
            small: "",
            depressed: "",
            disabled: _vm.$_isCopyAudioSegmentLabelDisabled,
          },
          on: { click: _vm.$_copyAudioSegmentLabel },
        },
        [_c("v-icon", [_vm._v("mdi-content-copy")])],
        1
      ),
      _c(
        "v-btn",
        {
          staticClass: "my-1 mr-2 py-1",
          attrs: {
            outlined: "",
            small: "",
            depressed: "",
            disabled: _vm.$_isPasteAudioSegmentLabelDisabled,
          },
          on: { click: _vm.$_pasteAudioSegmentLabel },
        },
        [_c("v-icon", [_vm._v("mdi-content-paste")])],
        1
      ),
      _c(
        "v-btn-toggle",
        {
          staticClass: "my-1",
          attrs: { dense: "", mandatory: "" },
          model: {
            value: _vm.$_selectedControlModeIdx,
            callback: function ($$v) {
              _vm.$_selectedControlModeIdx = $$v
            },
            expression: "$_selectedControlModeIdx",
          },
        },
        _vm._l(_vm.$_allControlModes, function (controlMode, idx) {
          return _c(
            "v-btn",
            {
              key: idx,
              staticClass: "py-1",
              attrs: { outlined: "", small: "" },
            },
            [
              _c(
                "v-icon",
                [
                  _vm.shiftKeyPressed
                    ? [
                        _vm._v(
                          " " + _vm._s(controlMode.alternativeIconId) + " "
                        ),
                      ]
                    : [_vm._v(" " + _vm._s(controlMode.iconId) + " ")],
                ],
                2
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }